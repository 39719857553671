<template>
    <FullCalendar
        class="demo-app-calendar"
        :options="calendarOptions"
    ></FullCalendar>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import FullCalendar, {CalendarOptions,EventApi,} from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "dashboard",
  components: {
    FullCalendar
  },
  props:{
      openEditModal : {
        type:Function,
        default: () => ({}),
      },
  },
  setup(props) {
    const todayDate = moment().startOf("day");
    const YM = todayDate.format("YYYY-MM");
    const YESTERDAY = todayDate
      .clone()
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    const TODAY = todayDate.format("YYYY-MM-DD");
    const TOMORROW = todayDate
      .clone()
      .add(1, "day")
      .format("YYYY-MM-DD");

    const calendarOptions = ref({
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            headerToolbar: {
              left: "prev,next today",
              center: "title",
              right:
                "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            },

            height: 800,
            contentHeight: 780,
            aspectRatio: 3, // see: https://fullcalendar.io/docs/aspectRatio

            nowIndicator: true,
            now: TODAY + "T09:25:00", // just for demo

            views: {
              dayGridMonth: { buttonText: "month" },
              timeGridWeek: { buttonText: "week" },
              timeGridDay: { buttonText: "day" },
            },

            initialView: "dayGridMonth",
            initialDate: TODAY,

            dayMaxEvents: true, // allow "more" link when too many events
            navLinks: true,
            eventClick : function(info){
                if(props.openEditModal){
                    var eventObj = info.event;
                    props.openEditModal(eventObj.extendedProps.rawData);
                }
            },
            events: [],
          });

    const getEvents = async () => {
        await new Promise((resolve, reject) => { 
            ApiService.setHeader();           
            ApiService.get("event")
                .then(({ data }) => {
                    var events = data.data.map((x) => { 
                      x.start = moment(x.start_date).format("YYYY-MM-DD"); 
                      x.end = moment(x.end_date).format("YYYY-MM-DD"); 
                      x.rawData = {...x};
                      return x;});
                    calendarOptions.value.events = events;
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getEvents error => '+response.data.errors));
                });
        });
    }

    onBeforeMount(() => {
        getEvents();
    })
    
    return {
      calendarOptions,
      getEvents
    }
  },
});
</script>